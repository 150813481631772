import React from 'react'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import { InfoCircleOutlined } from '@ant-design/icons';

export const ToolTip = ({ tooltipId, content }) => (
  <>
    <a
      data-tooltip-id={tooltipId}
      style={{ marginLeft: "0.25rem" }}
    >
      <InfoCircleOutlined />
    </a>
    <ReactTooltip
      id={tooltipId}
      place="top"
      style={{ display: 'block', maxWidth: "420px", zIndex: "12000000" }}
      openOnClick
    >
      <div className="react-tooltip-content">
        {content}
      </div>
    </ReactTooltip>
  </>
)

export default ToolTip
