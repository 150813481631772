import React from 'react';
import { Steps as AntdSteps } from 'antd';

export default ({ steps = [], current = 0, className = '' }) => (
  <AntdSteps
    size="small"
    current={current}
    className={className}
    steps={steps.map((step) => { return { title: step }; }) }
  />
);
