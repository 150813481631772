import React, { Component } from 'react';
import { Button, Input, Checkbox, Dropdown, Menu, DatePicker } from 'antd';
import { DownOutlined, CloseCircleOutlined, SearchOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

var isSameOrAfter = require('dayjs/plugin/isSameOrAfter');
var isSameOrBefore = require('dayjs/plugin/isSameOrBefore');
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

const stringConstants = {
  ALL: 'All',
};

export default class FilterDropDown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: '',
      open: false,
      ids: [],
      date: {
        start: '',
        end: '',
      },
      dateText: '',
    };
  }

  handleMenuItemClick = id => {
    const { ids = [] } = this.state;
    if (ids.includes(id)) this.setState({ ids: ids.filter(_id => _id !== id) });
    else this.setState({ ids: [...ids, id] });
  };
  createMenu = options => (
    <Menu>
      {options.map(({ id, title }) => (
        <Menu.Item key={id} onClick={() => this.handleMenuItemClick(id)}>
          <Checkbox checked={this.state.ids.includes(id)} />
          <span>{title}</span>
        </Menu.Item>
      ))}
    </Menu>
  );

  handleSearch = ({ target }) => {
    this.setState({ search: target.value });
  };

  handleDateChange = (dateString, field) => {
    const { date = {} } = this.state;
    date[field] = dateString;
    this.setState({ date });
  };

  getSearchItems() {
    const { ids = [], dateText = '' } = this.state;
    const { datePicker = false } = this.props;
    if (datePicker) return dateText || stringConstants.ALL;
    if (!ids.length) return stringConstants.ALL;
    return ids
      .map(id => this.props.options.find(opt => opt.id === id))
      .map(({ title = '' }) => title)
      .join(', ');
  }

  getDateValues = () => {
    const { datePicker = false } = this.props;
    const { dateText = '' } = this.state;
    let date = {
      start: '',
      end: '',
    };
    if (datePicker && dateText) {
      const [start, end] = dateText.split(' to ');
      date = {
        start,
        end,
      };
    }
    return date;
  };

  handleClear = e => {
    e.stopPropagation();
    const date = this.getDateValues();
    this.setState(
      {
        ids: [],
        open: false,
        dateText: '',
        date,
      },
      () => {
        this.props.onFiltersChange(null);
      }
    );
  };

  getDateValue = (date = '') => {
    const dateDayjs = dayjs(date);
    return dateDayjs.isValid() ? dateDayjs : '';
  };

  getDisabledStartDate = (currentDate, endDate) => {
    return currentDate && dayjs(currentDate).isSameOrAfter(endDate, 'date');
  };

  getDisabledEndDate = (currentDate, startDate) => {
    return currentDate && dayjs(currentDate).isSameOrBefore(startDate, 'date');
  };

  handleUpdateClick = (startDate, endDate) => {
    this.setState(
      {
        dateText: startDate && endDate ? `${startDate} to ${endDate}` : '',
        open: false,
      },
      () => this.props.onFiltersChange(startDate && endDate ? this.state.date : null)
    );
  };

  handleCloseClick = () => {
    const date = this.getDateValues();
    this.setState({ open: false, date });
  };

  isUpdateDisabled = () => {
    const {
      dateText = '',
      date: { start: startDate = '', end: endDate = '' },
    } = this.state;
    return !(dateText || startDate || endDate) || !!startDate !== !!endDate;
  };

  render() {
    const {
      ids = [],
      dateText = '',
      date: { start: startDate = '', end: endDate = '' },
      search,
    } = this.state;
    const { disabled = false, loading = false, options = [], datePicker = false } = this.props;

    const searchText = search ? search.toLowerCase() : '';

    const dropdownContent = () => {
      if (datePicker) {
        return (
          <div>
            <div
              style={{
                display: 'block',
                backgroundColor: '#fff',
                padding: '12px 12px 4px 12px',
              }}
            >
              <span style={{ margin: '0 10px' }}>From</span>
              <DatePicker
                value={this.getDateValue(startDate)}
                onChange={(date, dateString) => this.handleDateChange(dateString, 'start')}
                disabledDate={current => this.getDisabledStartDate(current, endDate)}
              />
              <span style={{ margin: '0 10px' }}>To</span>
              <DatePicker
                value={this.getDateValue(endDate)}
                onChange={(date, dateString) => this.handleDateChange(dateString, 'end')}
                disabledDate={current => this.getDisabledEndDate(current, startDate)}
              />
            </div>
            <div
              style={{
                display: 'block',
                backgroundColor: '#fff',
                padding: '2px 12px 12px 12px',
              }}
            >
              <Button
                disabled={this.isUpdateDisabled()}
                style={{ width: 120 }}
                type="primary"
                onClick={() => this.handleUpdateClick(startDate, endDate)}
              >
                Update
              </Button>
              <Button style={{ color: '#000' }} onClick={this.handleCloseClick} type="link">
                Close
              </Button>
            </div>
          </div>
        );
      } else {
        if (!options.length)
          return (
            <div
              style={{
                padding: '10px',
                textAlign: 'center',
                backgroundColor: '#fff',
              }}
            >
              No Records
            </div>
          );
        return (
          <div>
            <Input
              onChange={this.handleSearch}
              value={this.state.search}
              type="text"
              placeholder="Search"
              suffix={<SearchOutlined />}
            />
            {this.createMenu(
              options.filter(
                option =>
                  String(option.id).includes(searchText) ||
                  option.title.toLowerCase().includes(searchText)
              ) || []
            )}
          </div>
        );
      }
    };

    return (
      <Dropdown
        visible={this.state.open}
        trigger={['click']}
        overlay={dropdownContent()}
        placement="bottomLeft"
        onVisibleChange={e => {
          const date = this.getDateValues();
          this.setState({ open: e, search: '', date });
          if (!e && !datePicker) this.props.onFiltersChange(ids.length ? ids : null);
        }}
        disabled={disabled}
      >
        <Button loading={loading}>
          <div className="filter-title">
            {this.props.placeholder}: {this.getSearchItems()}
          </div>
          <div className="filter-buttons">
            <DownOutlined />
            {ids.length || dateText ? (
              <CloseCircleOutlined
                onClick={this.handleClear}
                style={{ color: '#D8D8D8' }}
              />
            ) : null}
          </div>
        </Button>
      </Dropdown>
    );
  }
}
