import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Card, Button } from 'antd';

import { SUBSCRIPTION_TYPES } from '../utils/constants';
import ToolTip from './ToolTip';

const SelectSubscriptionButton = ({ subscriptionType, loading, submitSubscription, isEdit, upgradeTitle, showCancel, subscriptionTitle }) => (
  <Button
    size="large"
    loading={subscriptionType === loading}
    disabled={loading && subscriptionType !== loading}
    onClick={() => submitSubscription(subscriptionType)}
    style={{
      margin: '32px 0',
    }}
    {...(showCancel
         ? { type: 'danger' }
         : subscriptionType === SUBSCRIPTION_TYPES.PREMIUM
         ? { type: 'primary' }
         : { type: 'default' })}
  >
    {showCancel ? 'Cancel Subscription' : isEdit ? upgradeTitle : `Select ${subscriptionTitle}`}
  </Button>
);

const MaxBasicInstructorsWarning = ({ maxBasicInstructors }) => (
  <>
    <p>
      The Basic plan allows for a total of {maxBasicInstructors} instructors to be active in
      Instrukt at a time.
    </p>
    <p>
      If you activate more than {maxBasicInstructors} instructors you will need to upgrade to the
      Premium plan, or if you plan on activating more than {maxBasicInstructors} instructors you
      may choose the Premium plan now.
    </p>
  </>
);

const AddonText = ({ maxPremiumLocations, addonCost }) => (
  <>
    <p>
      The Premium includes {maxPremiumLocations} location. You will be billed ${addonCost} for
      each additional location.
    </p>
    <p>All locations must be under the same Mindbody siteID.</p>
    <p>If you have multiple siteIDs a separate Instrukt subscription is required for each.</p>
  </>
);

const SubscriptionCard = ({
  plans,
  subscription,
  submitSubscription,
  subscriptionType,
  loading = '',
  showCancel = false,
  isEdit = false,
}) => {
  const metadata = subscription.metadata;
  const subscriptionTitle = metadata.plan_title || metadata.plan_name;

  // plans
  const basicPlan = plans.basic[0];
  const premiumPlan = plans.premium[0];
  const addonPlan = plans.premium[1];

  // plan variables
  const addonCost = addonPlan.amount / 100;
  const maxBasicInstructors = basicPlan.metadata.max_instructors;
  const maxPremiumLocations = premiumPlan.metadata.max_locations;
  const subscriptionTotal = getTotalCost(subscriptionType);

  function getTotalCost(subscriptionType) {
    let planTotal = 0;

    if (subscriptionType === 'basic') {
      planTotal = basicPlan.amount / 100;
    } else if (subscriptionType === 'premium') {
      planTotal = (premiumPlan.amount) / 100;
    }

    return planTotal;
  }

  const upgradeTitle =
    subscriptionType === SUBSCRIPTION_TYPES.PREMIUM
      ? `Upgrade to ${subscriptionTitle}`
      : `Downgrade to ${subscriptionTitle}`;

  // tooltip text variables

  const StudioSupport = () => {
    if (subscriptionType === SUBSCRIPTION_TYPES.BASIC)
      return <div>For studios with only {subscription.metadata.max_locations} location</div>;
    else if (subscriptionType === SUBSCRIPTION_TYPES.PREMIUM)
      return (
        <div>
          {subscription.metadata.max_locations} studio location included
          <ToolTip tooltipId="locations-tooltip" content={<AddonText maxPremiumLocations={maxPremiumLocations} addonCost={addonCost} />} />
        </div>
      );
  };

  const ActiveInstructors = () => {
    if (subscriptionType === SUBSCRIPTION_TYPES.BASIC)
      return (
        <div>
          Active Instructors: Up to {subscription.metadata.max_instructors}
          <ToolTip tooltipId="active-instructors-tooltip" content={<MaxBasicInstructorsWarning maxBasicInstructors={maxBasicInstructors} />} />
        </div>
      );
    else if (subscriptionType === SUBSCRIPTION_TYPES.PREMIUM)
      return <div>Active Instructors: {subscription.metadata.max_instructors}</div>;
  };

  const AddonCost = () => {
    if (subscriptionType === SUBSCRIPTION_TYPES.PREMIUM)
      return (
        <div>
          ${addonCost} Additional Locations
          <ToolTip tooltipId="additional-loc-tooltip" content={<AddonText maxPremiumLocations={maxPremiumLocations} addonCost={addonCost}  />} />
        </div>
      );
    return null;
  };

  const LocationSuppport = () => {
    if (subscriptionType === SUBSCRIPTION_TYPES.PREMIUM)
      return <div>Support for Multiple Locations Under one MINDBODY Site ID</div>;
    return null;
  };

  return (
    <div className="subscription-card-container">
      <div className="card-container-header">
        <h3>
          {subscriptionTitle} ${subscriptionTotal}
        </h3>
        <div>per month</div>
      </div>
      <Card
        title={
          <Fragment>
            <div className="header-title">Plan Total: </div>
            <div>${subscriptionTotal} Plan Price</div>
            <AddonCost />
          </Fragment>
        }
        className="subscription-card"
        style={{ width: 280, height: 'calc(100% - 112px)' }}
        bordered={false}
      >
        <div>{subscription.metadata.trial_period} day FREE trial</div>
        <div>Integration with one MINDBODY site ID</div>
        <LocationSuppport />
        <StudioSupport />
        <ActiveInstructors />
      </Card>
      <SelectSubscriptionButton
        subscriptionType={subscriptionType}
        loading={loading}
        submitSubscription={submitSubscription}
        isEdit={isEdit}
        upgradeTitle={upgradeTitle}
        showCancel={showCancel}
        subscriptionTitle={subscriptionTitle}
      />
    </div>
  );
};

SubscriptionCard.propTypes = {
  getTotalCost: PropTypes.func,
  plans: PropTypes.object,
  loading: PropTypes.string,
  submitSubscription: PropTypes.func,
  subscription: PropTypes.object.isRequired,
  subscriptionType: PropTypes.string.isRequired,
};

export default SubscriptionCard;
