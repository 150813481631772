import React from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import styles from './Styles/LinkButtonStyles';

const LinkButton = ({ linkAction, linkText = '', loading = false, linkStyle = {} }) => (
  <div className="LinkButton" style={styles.linkContainer} onClick={linkAction}>
    {loading ? (
      <Spin indicator={<LoadingOutlined />} />
    ) : (
      <p style={{ ...styles.linkText, ...linkStyle }}>{linkText}</p>
    )}
  </div>
);

LinkButton.propTypes = {
  linkAction: PropTypes.func.isRequired,
  linkText: PropTypes.string.isRequired,
  loading: PropTypes.bool,
};

export default LinkButton;
